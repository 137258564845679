import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

import axios from 'axios'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { BButton, BModal, vBModal } from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

app.component('BButton', BButton);
app.component('BModal', BModal);

app.directive('b-modal', vBModal);

const options = {
    confirmButtonColor: '#efce03',
    cancelButtonColor: '#1d1d29',
};

app.use(VueSweetalert2, options);

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import ReadonlyDirective from './diretivas/readonly';

app.directive('readonly', ReadonlyDirective);

import VueClipboard from 'vue-clipboard2';
app.use(VueClipboard);

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
app.component('QuillEditor', QuillEditor)

app.use(store);
app.use(router);

app.component('vue-phone-number-input', VuePhoneNumberInput);

// const urlAPI = 'http://127.0.0.1:8000';
// app.config.globalProperties.baseStorage = urlAPI + '/storage/';
// axios.defaults.baseURL = urlAPI + '/api/v1';
// app.config.globalProperties.$base = 'https://inoveng.com/';
// app.config.globalProperties.$api = urlAPI + '/api/v1/';

const urlAPI = 'https://app.inoveng.com';
app.config.globalProperties.baseStorage = urlAPI + '/storage/';
axios.defaults.baseURL = urlAPI + '/api/v1';
app.config.globalProperties.$base = 'https://inoveng.com/';
app.config.globalProperties.$api = urlAPI + '/api/v1/';

axios.defaults.headers.common['Accept'] = 'application/json';
app.config.globalProperties.$http = axios;

import { vMaska } from "maska/vue"
app.directive('maska', vMaska);

import money from 'v-money3'
app.use(money);

import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
app.use(CkeditorPlugin);

app.mount('#app');